// Fonts
@import "fonts";
// For grouping your css in mixins
@import "mixins";
// To overwrite default Foundation style
@import "foundation_overwrites";

/*
 * GENERAL CLASSES
 *
 * In many cases you'll need the same css multiple times for different elements throughout your website.
 * Place the classes with those css in this file.
 */

html,body{height:100%; overflow:hidden;}

/* Default element styles */
a{ transition:color .2s ease-in-out, background-color .2s ease-in-out;}
strong{
    margin-bottom:.5rem;
    font-weight:500;
}

/* Texts */
.truncated-text{
    text-overflow:ellipsis; white-space:nowrap;
    overflow:hidden;
}

/* Forms */
input[type="file"]{margin-top: .5rem;}
select{appearance: none !important;}
.icn-label{
    margin-left:.5rem;
    font-size:1.6rem;
}
.small-label-text{font-size:85%; font-weight:400; line-height:1.8;}
.checkbox, .radio{
    @include position($position: relative);
    padding-left:2.5rem;
    display:block;
}
.checkbox input[type="checkbox"], .radio input[type="radio"]{
    @include position($position: absolute, $left: auto);
    margin:.3rem 0 .5rem -2.5rem;
}

.icon{padding:0 .5rem 0 0;}

.bg-transition{transition:background-color 0.4s ease-in-out;}

/* Alert boxes */
.alert-box{
    @include alert;
    .close{ @include alert-close; }
    &.radius{border-radius:$alert-radius;}
    &.primary{ @include alert-style($primary-color); }
    &.secondary{ @include alert-style($secondary-color); }
    &.success{ @include alert-style($success-color); }
    &.alert{ @include alert-style($alert-color); }    
    &.warning{ @include alert-style($warning-color); } 
    &.alert-close{opacity:0;}
    ul{margin-bottom:0;}
    a{
        color:$white; text-decoration:underline;
        &:hover{text-decoration:none;}
    }
}

/* Admin primary color shades */
.primary-full{background-color:$primary-color;}
.primary-lighter{background-color:lighten($primary-color, 6%);}
.primary-lightest{background-color:lighten($primary-color, 12%);}
.primary-desaturated{background-color:lighten( desaturate($primary-color, 40%), 28%);}

.is-relative{ @include position($position: relative); }

/* IE fixes */
.is-ie9{
    .list-item{
        .badge{top:30%;}
    }
}

/* Animation for locked buttons */
@keyframes is-locked {
    0%{left:0;width:0}
    50%{left:0;width:100%}
    100%{left:100%;width:0}
}

.button{
    &.is-locked{
        @include position($position: relative);
        background-color:scale-color($success-color, $saturation: -50%, $lightness: 65%);
        &::after{
            @include position($position: absolute, $top: auto, $bottom: 0);
            width:1rem; height:.3rem;
            background-color:$success-color;
            content: "";
            animation:is-locked 1.3s infinite; 
        } 
    }
}

/*
 * ADMIN STYLES
 *
 * All of your custom css classes and styles can be placed below.
 * Do NOT forgot to add comments above the different sections of styles.
 * For instance: styles that refer to news, add the comment /* NEWS * / above.
 *               styles that refer to product detail page, add the comment /* PRODUCT DETAIL * /
 *               Etc.
 */

/* Login page */
.non-auth{overflow-y:visible;}
.login-wrapper{
    width:100%; max-width:46rem; padding:$global-padding 0;
    font-size:$body-font-size;
}

.login-content{
     padding:($global-padding * 2) $global-padding;
     background-color:$white;
}

.powered-by{
    margin-top:1.5rem;
    font-size:1.1rem; color:$body-font-color;
    display:inline-block;
}

.powered-by-logo{margin-left:.5rem;}

.client-logo-wrapper{
    width:10rem; height:10rem; margin:0 auto 3rem auto;
    display:block;
}

/* Basics CMS */
.main{
    @include position($position: relative);
    height:100%;
    overflow:hidden;
}

/* Header top bar */
.header-wrapper{
    @include position($position: relative, $zindex: 1005);
    width:100%; height:9rem;
}

.site-name{
    @include position($position: absolute, $zindex: 1010);
    width:100%;
    text-align:center; line-height:4rem; font-size:2rem; color:$white;
    background-color:darken($primary-color, 5%);
    a, a:hover{color:$white;}
}

.icn-menu-wrapper{
    height:4.9rem; padding:1.3rem 2rem;
    float:left;
    display:inline-block;
    transition:background-color .4s ease-in-out;
    &:hover{background-color:$dark-primary-color;}
}

.icn-menu{ @include hamburger($color: $white, $color-hover: $white); }

/* Sidebar */
.sidebar-wrapper{
    @include position($position: absolute, $zindex: 1000, $left: -25rem);
    width:250px; height:100%; padding-top:9rem;
    background-color:$dark-gray; color:$white; 
    transition:left .4s ease-in-out;
}

.side-scrollbar-wrapper{
    @include position($position: relative);
    height:100%; padding-bottom:3.2rem;
    overflow:hidden;
}

.sidebar-colofon{
    @include position($position: absolute, $top: auto, $right: 0, $bottom: 0);
    height:3.2rem;
    background-color:$dark-gray;
    text-align:center;
    .powered-by{
        margin-top:.4rem;
        font-size:1rem; color:darken($white, 20%);
    }
}

.sidebar-category{
    padding:.8rem $global-padding;
    line-height:1; color:lighten($dark-gray, 25%); text-transform:uppercase; font-size:1.2rem;
    background-color:darken($dark-gray, 7%);
}

.colofon-placeholder{height:3.2rem;}

/* Content */
.page-wrapper{
    @include position($position: relative);
    height:100%; margin-top:-9rem; padding-top:9rem;
    transition:margin-left .4s ease-in-out, left .4s ease-in-out;
}

.page-scrollbar-wrapper{
    @include position($position: relative);
    height:100%; padding:2rem $global-padding 0 $global-padding;
    overflow:hidden;
}

.page-inner-wrapper{
    padding-bottom:8rem;
    opacity:1;
    transition:opacity .4s ease-in-out;
    &.hidden{opacity:0;}
}

.content-wrapper{
    margin-bottom:2.5rem; padding-top:$global-padding;
    background-color:$white; border-top:2px solid lighten($medium-gray, 5%); border-bottom:1px solid lighten($medium-gray, 5%);
}
.content-header{
    padding:0 $global-padding $global-padding $global-padding;
    border-bottom: 1px solid lighten($medium-gray, 5%);
}
.content-title{margin-bottom:0;}
.content-body{padding:$global-padding;}
.content-footer{
    padding:$global-padding;
    border-top:1px solid lighten($medium-gray, 5%);
    &.is-fixed-bottom{
        @include position($position: fixed, $top: auto, $right: 0, $bottom: 0);
        background-color:$white;
        transition:left .4s ease-in-out, width .4s ease-in-out;
        -webkit-transform:translateZ(0);
        &.stretch-footer{
            width:1000rem; 
            left:25rem;
        }
    }
    .button{margin-bottom:0;}
}

/* Ajax loader */
.loader-wrapper{
    @include position($position: absolute, $top: 50%, $right: 0);
    width:20rem; margin:auto;    
    text-align: center;
    transform:translateY(-55%);
}
.loader{        
    width:5rem; height:5rem; margin:0 auto 1rem auto;
}

/* Animate sidebar & content */
.sidebar-expand{
    .sidebar-wrapper{left:0;}
    .page-wrapper{left:25rem;}
}
.sidebar-collapse{
    .sidebar-wrapper{left:-25rem;}
    .page-wrapper{margin-left:0;}   
}

.close-sidebar{
    @include position($position: absolute, $zindex: 1000, $right: 0, $bottom: 0);
    background-color:rgba($dark-gray, .4);
}

/* Custom scrollbar */
.ps-theme-dark{
    @include ps-container(
        map-merge($ps-theme-default, (
            bar-container-hover-bg: $dark-gray,
            bar-hover-bg: $dark-gray,
            rail-hover-bg: darken($medium-gray, 5%),
        )
    ));
    .ps-scrollbar-y-rail{z-index:1000;}
}

.ps-theme-light{
    @include ps-container(
        map-merge($ps-theme-default, (
            bar-container-hover-bg: darken($medium-gray, 20%),
            bar-hover-bg: darken($medium-gray, 35%),
            rail-hover-bg: darken($dark-gray, 10%),
        )
    ));
    .ps-scrollbar-y-rail{z-index:1000;}
}

/* Dashboard */
// flot chart overwrites
.content-body{
    .legendColorBox, .legendLabel{
        font-size:1.2rem;
        border-top:0;
    }
}

.panel-wrapper{
    margin-bottom:1.5rem; padding:$global-padding;
    color:$white;
    background-color:$primary-color; border-radius:.2rem;  
    &.first{@extend .primary-full;}
    &.second{@extend .primary-lighter;}
    &.third{@extend .primary-lightest;}
}

.stat-wrapper{
    width:50%;
    font-size:1.2rem;
    &.top{width:70%; margin-bottom:.5rem;}
    .medium-number{font-size:1.4rem;}
    .large-number{font-size:3.2rem; line-height:1.3;}
}

.stat-label{
    line-height:1.2;
    display:block;
}

.stat-icon{
    width:30%; padding-top:.5rem;
    font-size:4.5rem; text-align:right;
    .icn::before{float:right;}
}

.flot-tooltip{
    padding:2px 5px;
    position:absolute; z-index:100;
    font-size:1.1rem; color:$white;
    background-color:$primary-color; border-radius:2px;
    display:none; opacity:.9;
}

/* DataTables overwrites */
.dataTables_wrapper{
    .content-body{padding-top:0; padding-bottom:0;}
}

.content-header{
    .dataTables_length{
        @extend .clearfix;
        margin-bottom:1.5rem;
        label{color:$body-font-color;}
    }
    .dataTables_filter label{
        @include position($position: relative);
        width:100%;
        .icon{
            @include position($position: absolute, $zindex: 5, $top: 6px, $left: 8px);
        }
        input{width:100% !important; padding-left:3rem;}
    }
}

.content-body{
    .dataTable{
        &.dtr-inline.collapsed{
            td:first-child::before{
                @extend .icon-font;
                color:$primary-color;
                background-color:transparent; border:0; border-radius:0; box-shadow:none; box-sizing:inherit;
                content:"\65";
            }
            .parent td:first-child::before{
                background-color:transparent;
                content:"\75";
            }   
        }
        
        thead{
            text-align:left;
            .sorting{
                background:none;
                &::after{content:"\70"; color:lighten($body-font-color, 30%);}
            }
            .sorting_asc{
                background:none;
                &::after{content:"\72"; color:$primary-color;}
            }
            .sorting_desc{
                background:none;
                &::after{content:"\71"; color:$primary-color;}
            }
            .sorting_asc_disabled{
                background:none;
                &::after{content:"\72"; color:lighten($body-font-color, 50%);}
            }
            .sorting_desc_disabled{
                background:none;
                &::after{content:"\71"; color:lighten($body-font-color, 50%);}
            }
        }
        tbody tr{
            transition:background-color .2s ease-in-out;
            &:hover{background-color:lighten($light-gray,2%);}
        }
    }
    .view-table{
        width:100%;
        tr:first-child{
            th, td{border-top:0;}
        }
    }
    thead{
        th{
            border-top:0; border-bottom:2px solid $medium-gray; background:none;
            &::after{
                @extend .icon-font;
                margin-left:.5rem;
            }
        }
    }
    th, td{
        padding:1rem 1rem 1rem .5rem;
        font-size:1.4rem; text-align:left;
        border-top:1px solid $medium-gray;
    }
    .label{font-weight:700;}
    .delete-form{display:inline-block;}
    .btn-trash, .btn-edit{
        color:$primary-color; font-size:1.6rem;
        &:hover{color:$anchor-color-hover;}
    }
    .child li{
        font-size:1.4rem;
        border-bottom:1px solid $medium-gray;
    }
}

.content-footer{
    .dataTables_info{
        margin-bottom:1.5rem;
        color:$body-font-color; text-align:center; font-size:1.4rem;
    }
    .dataTables_paginate{
        width:100%;
        text-align:center;
        .pagination{
            margin:0;
            .paginate_button{
                width:3rem; height:3rem;
                line-height:3rem; text-align:center;
                a, button{padding:0;}
            }
            .current{
                padding:0;
                background-color:$primary-color;
                display:inline-block;
                a, button{
                    color:$white;
                    &:hover{background-color:darken($primary-color, 10%);}
                }
            }
            .unavailable{display:none;}
            a, button{
                transition:background-color .2s ease-in-out;
                &:hover{background-color:darken($medium-gray, 10%);}
            }
        }
    }
}

/* Lists */
.list-wrapper{margin:0;}
.list-item{
    @include position($position: relative);
    padding:1rem 4rem 1rem 0;
    list-style-position:inside; border-top:1px solid lighten($medium-gray, 5%);
    &:first-child{border-top:0;}
    .badge{
        @include position($position: absolute, $top:50%, $right: 0, $left: auto);
        transform:translateY(-50%);
    }
}
.list-item-title{padding-left:10px;}

/* TinyMCE overwrites */
.mce-tinymce{margin-bottom:$form-spacing !important;}

/* Sort page */
.sort-item-wrapper{    
    .sortable-chosen{border: dashed 1px $dark-gray; background-color:$medium-gray;}
}
.sort-item{
    padding:1rem 5rem 1rem .5rem;
    list-style-type:none; border:solid 1px transparent; border-top: 1px solid lighten($medium-gray, 5%);
    display:list-item;
    cursor:move;
    &::before{
        @extend .icon-font;
        content:"\70";
    }
}
.sortable-ghost{
    opacity:0.4;
    .gallery-item{
        border-color:$dark-gray; background-color:$medium-gray;
        &::before{color:$dark-gray;}
    }
}

/* Edit single images / files */
.single-image-wrapper{margin:0 0 1.5rem 0;}
.single-image-title{ @include position($position: relative); }
.single-image-options{
    margin:1.5rem 0;
    .button{font-weight:500;}
}
.file-info{
    margin-bottom:1.5rem;
    display:block;
}
.icn-selected-file{
    margin:0 .5rem 1.5rem 0;
    display:inline-block;
}
.icn-edit-media-title{
    margin-left:.5rem;
    color:$primary-color;
}

/* Edit content images */
.image-list-wrapper{margin:0 0 1.5rem 0; }
.image-list-item{
    padding:$global-padding 0;
    border-top:1px solid $medium-gray; list-style-type:none;
    &:first-child{border-top:0;}
}
.image-wrapper{
    width:12rem; height:9.8rem; margin:0;
    .image{max-width:12rem; height:9.8rem;}
}
.image-list-options{
    @include position($position: absolute, $top: 1.5rem, $right: 0, $left: auto);
    width:100%; height:9.8rem; padding-left:13rem;
}
.icn-image-info{
    float:left;
    font-size:1.8rem;
    display:inline-block;
}

/* Edit gallery images */
.gallery-wrapper{
    margin:0;
    .button-group{margin-bottom:0;}
    .sortable-placeholder{
        width:100%; height:20.1rem; margin-bottom:1.5rem;
        float:left;
    }
}
.gallery-item-wrapper{
    margin-bottom:1.5rem; padding:0 ($global-padding / 2);
    list-style-type:none;
    cursor:move;
    &.non-default-lang{
        cursor:default;
        .gallery-item{
            border-style:solid;
            &::before{content:"";}
        }
    }
}
.gallery-item{
    @include position($position: relative);
    padding:$global-padding;
    border:1px dashed $medium-gray;
    &::before{
        @include position($position: absolute, $top: -1.3rem, $right: -.8rem, $left: auto);
        @extend .icon-font;
        content:"\47"; color:darken($medium-gray, 5%);
    }
}
.gallery-image-wrapper{
    margin:0; padding-bottom:$global-padding;
    text-align:center;
    overflow:hidden;
    cursor:default;
    .image{max-width:none; max-height:12.5rem;}
}

// Sorting gallery images
@include -zf-each-breakpoint{
    @for $i from 1 through $block-grid-max {
        .#{$-zf-size}-up-#{$i} {
            @include grid-layout($i, ".sortable-placeholder");
        }
    }
}

/* Cropper */
.cropper-container{margin-bottom:1.5rem;}
.cropper-preview{
    width:18rem; height:12.5rem; margin:.5rem auto; 
    overflow:hidden;
}

/* Media query min-height: 570px */
@media only screen and (min-height: 57rem){

    /* IE fixes */
    .is-ie9{
        &.login-wrapper{
            width:100%;
            top:25%;
        }
    }

    /* Login page */
    .login-wrapper{
        @include position($position: absolute, $top: 50%, $left: 50%);
        padding:0;
        transform:translate(-50%, -50%);
    }

}

/* Media query min-width: 480px */
@include breakpoint(medium) {

    /* Login page */
    .login-wrapper{margin:0 auto;}

    /* DataTables overwrites */
    .content-footer{
        .dataTables_info{
            margin-bottom:0;
            text-align:left;
        }
        .dataTables_paginate{text-align:right;}
    }

}

/* Media query min-width: 768px */
@include breakpoint(large) {

    /* Header top bar */
    .header-wrapper{height:6rem;}

    .site-name{
        width:25rem; height:6rem;
        line-height:6rem;
    }

    .icn-menu-wrapper{height:6rem; padding:1.8rem 2rem;} 

    /* Sidebar */
    .sidebar-wrapper{padding-top:6rem;}

    /* Content */
    .page-wrapper{margin-top:-6rem; padding-top:6rem;}

    /* DataTables overwrites */
    .content-header{
        .dataTables_length{margin-bottom:0;}
    }

}

/* Media query min-width: 1024px */
@include breakpoint(xlarge) {
    
    /* Sidebar */
    .sidebar-wrapper{left:0;}

    /* Content */
    .page-wrapper{margin-left:25rem;}

    .button-wrapper{text-align:right;}    

    .content-footer.is-fixed-bottom{left: 25rem;}
    
    .sidebar-collapse{
        .content-footer.is-fixed-bottom{left:0;}
    }

}